import React from 'react';
import { ReactComponent as Icon1 } from './../../../assets/img/icons/categorySelect.svg';
import { ReactComponent as Icon2 } from './../../../assets/img/icons/dateTimeSelect.svg';
import { ReactComponent as Icon3 } from './../../../assets/img/icons/form.svg';
import { withTranslation } from 'react-i18next';
import CardComponent from "./card/Card";
import CategorySelectComponent from "./card/cardContent/categorySelect";
import DateSelectComponent from "./card/cardContent/DateTimeSelect";
import FormComponent from "./card/cardContent/ContactForm";
import SideComponent from "../side/Side";

class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: '',
            date: '',
            progress: 0,
            categoryStatus: 'active',
            dateStatus: 'disabled',
            formStatus: 'disabled',
        };

        this.category = React.createRef();
        this.date     = React.createRef();
        this.form     = React.createRef();

        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCategoryActivation = this.handleCategoryActivation.bind(this);
        this.handleDateActivation = this.handleDateActivation.bind(this);
        this.handleFormActivation = this.handleFormActivation.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formStatus !== prevProps.formStatus) {
            if(this.props.formStatus === 'success') {
                this.setState({
                    dateStatus: 'success',
                    categoryStatus: 'success',
                    formStatus: 'success'
                });
            } else if(this.props.formStatus === 'failed') {
                this.scrollToSection(this.date);
            }
        }
    }

    render() {
        const {t, config} = this.props;

        return (
            <div className="App-Main row">
                <div className="col-xl-8">
                    <div
                        ref={this.category}
                        className="card-wrapper category-wrapper"
                        onClick={this.handleCategoryActivation}
                    >
                        <CardComponent
                            title={t('card.categorySelect.title')}
                            text={t('card.categorySelect.text')}
                            className={this.state.categoryStatus}
                        >
                            <Icon1 fill={config.color}/>
                            <CategorySelectComponent
                                onCategoryChange={this.handleCategoryChange}
                                onCategoryClick={this.handleCategoryClick}
                            />
                        </CardComponent>
                    </div>
                    <div
                        ref={this.date}
                        className="card-wrapper date-wrapper"
                        onClick={this.handleDateActivation}
                    >
                        <CardComponent
                            title={t('card.dateSelect.title')}
                            text={t('card.dateSelect.text')}
                            className={this.state.dateStatus}
                        >
                            <Icon2 fill={config.color}/>
                            <DateSelectComponent
                                category={this.state.category}
                                onDateChange={this.handleDateChange}
                                formStatus={this.props.formStatus}
                                color={config.color}
                            />
                        </CardComponent>
                    </div>
                    <div
                        ref={this.form}
                        className="card-wrapper form-wrapper"
                        onClick={this.handleFormActivation}
                    >
                        <CardComponent
                            title={t('card.form.title')}
                            text={t('card.form.text')}
                            component="form"
                            className={this.state.formStatus}
                        >
                            <Icon3 fill={config.color}/>
                            <FormComponent
                                category={this.state.category}
                                date={this.state.date}
                                formStatus={this.props.formStatus}
                                config={config}
                            />
                        </CardComponent>
                    </div>
                </div>
                <div className="col-xl-4">
                    <SideComponent
                        color={config.color}
                        category={this.state.category}
                        date={this.state.date}
                        categoryStatus={this.state.categoryStatus}
                        dateStatus={this.state.dateStatus}
                        formStatus={this.state.formStatus}
                        onCategoryActivation={this.handleCategoryActivation}
                        onDateActivation={this.handleDateActivation}
                        onFormActivation={this.handleFormActivation}
                    />
                </div>
            </div>
        )
    }

    // handle category change event
    handleCategoryChange(category) {
        this.setState({
            category: category,
            categoryStatus: 'selected',
            dateStatus: 'active'
        });
        this.scrollToSection(this.date);
    }

    // handle category click event
    handleCategoryClick(category) {
        this.handleCategoryChange(category);
    }

    // handle category activation, after category card is clicked
    handleCategoryActivation() {
        if(this.state.categoryStatus === 'selected') {
            // check if date is selected
            if(this.state.date) {
                this.setState({
                    dateStatus: 'selected',
                    formStatus: 'selected'
                });
            } else {
                this.setState({
                    dateStatus: 'disabled',
                    formStatus: 'disabled'
                });
            }
            this.setState({categoryStatus: 'active'});
            this.scrollToSection(this.category);
        }
    }

    // handle date change
    handleDateChange(date) {
        this.setState({date: date});

        if(date) {
            this.setState({
                dateStatus: 'selected',
                formStatus: 'active'
            });
            this.scrollToSection(this.form);
        }

    }

    // handle date activation, after date card is clicked
    handleDateActivation() {
        if(this.state.category && this.state.dateStatus === 'selected') {
            // check if date is set
            if(this.state.date) {
                this.setState({formStatus: 'selected'});
            } else {
                this.setState({formStatus: 'disabled'});
            }
            this.setState({
                categoryStatus: 'selected',
                dateStatus: 'active'
            });
            this.scrollToSection(this.date);
        }
    }

    handleFormActivation() {
        if(this.state.formStatus === 'selected') {
            this.setState({
                formStatus: 'active',
                dateStatus: 'selected',
                categoryStatus: 'selected'
            });
            this.scrollToSection(this.form);
        }
    }

    scrollToSection(element) {
        if(element.current.clientHeight < window.innerHeight) {
            element.current.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        } else {
            element.current.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
        }
    }
}

export default withTranslation('text')(MainComponent);