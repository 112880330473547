import React from 'react';
import { useTranslation } from 'react-i18next';
import websiteIcon from './../../assets/img/icons/website.svg';

function HeaderComponent(props) {
    const { t } = useTranslation('text');
    const config = props.config;
    const logo = require(`./../../../instanzen/${props.company}/${config.logo.name}`);

    return (
        <header className="App-header">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="App-logo">
                                    <img src={logo} alt="logo" />
                                </div>
                            </div>
                            <div className="col">
                                <h1 className="h4">
                                    <a
                                        className="company-link text-decoration-none"
                                        href={config.linkToWebsite}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {config.companyName}
                                    </a>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col d-none d-md-block">
                        <ul className="nav justify-content-end">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href={config.linkToWebsite}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img src={websiteIcon} alt="" width="20" height="" />
                                    <span className="ms-2">{t('header.companyLink')}</span>
                                </a>
                            </li>
                            {/*TODO: add help pop-up*/}
                            <li className="nav-item d-none">
                                <a className="nav-link" href="#">{t('header.help')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderComponent;