import React from 'react';

class CardComponent extends React.Component {
    render() {
        const icon = this.props.children[0];
        const component = this.props.children[1];

        return (
            <div className={`card ${this.props.className}`}>
                <div className="card-inner">
                    <div className="row mb-4">
                        <div className="col-auto">
                            {icon}
                        </div>
                        <div className="col-8">
                            <h2 className="card-title">{this.props.title}</h2>
                            <div className="card-content d-none d-sm-block">{this.props.text}</div>
                        </div>
                    </div>
                    <div className="card-component">
                        {component}
                    </div>
                </div>
                <div className="separator"/>
            </div>
        )
    }
}

export default CardComponent;