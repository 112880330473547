import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form'
import {Button} from "react-bootstrap";
import { ReactComponent as Note } from './../../../../../assets/img/icons/note.svg';
import { ReactComponent as Arrow } from './../../../../../assets/img/icons/toggleArrow.svg';
import { ReactComponent as Loader } from './../../../../../assets/img/icons/loader.svg';

function FormComponent(props) {
    const { t } = useTranslation('text');
    const [isVisible, setVisible] = useState(false);
    let submitSelection = '';
    let disabled = true;

    if(props.date) {
        submitSelection = '('+props.date+' Uhr)';
        disabled = false;
    }

    const toggleComment = () => {
        setVisible(!isVisible);
    }

    if(props.formStatus === 'success') {
        return (
            <div className="form-success-message">
                <div className="text-center">
                    <p className="h4">{t('card.form.success.title')}</p>
                    <p>{t('card.form.success.text')}</p>
                    <p>
                        <a
                            className="btn btn-primary"
                            href={props.config.linkToWebsite}
                            rel="noopener noreferrer"
                        >
                            {t('header.companyLink')}
                        </a>
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <div className={props.formStatus}>
                <div className="loader text-center">
                    <Loader fill={props.config.color} width="64"/>
                </div>
                <div className="row form-row">
                    <Form.Group className="mb-3 col-md-4" controlId="salutation">
                        <Form.Control required as="select" className="form-select" aria-label={t('card.form.salutation.label')}>
                            <option value="" disabled selected>{t('card.form.salutation.label')}</option>
                            <option value={t('card.form.salutation.options.female')}>{t('card.form.salutation.options.female')}</option>
                            <option value={t('card.form.salutation.options.male')}>{t('card.form.salutation.options.male')}</option>
                            <option value={t('card.form.salutation.options.notSpecified')}>{t('card.form.salutation.options.notSpecified')}</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.salutation.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-8" controlId="name">
                        <Form.Control required type="text" placeholder={t('card.form.name.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.name.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="street">
                        <Form.Control required type="text" placeholder={t('card.form.street.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.street.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="zip">
                        <Form.Control required type="text" inputmode="numeric" pattern="[0-9]*" placeholder={t('card.form.zip.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.zip.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="city">
                        <Form.Control required type="text" placeholder={t('card.form.city.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.city.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="email">
                        <Form.Control required type="email" placeholder={t('card.form.email.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.email.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="phone">
                        <Form.Control type="tel" placeholder={t('card.form.phone.label')} />
                        <Form.Control.Feedback type="invalid">
                            {t('card.form.phone.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 comment-element" controlId="comment">
                        <Form.Label onClick={toggleComment}>
                            {t('card.form.comment.label')}
                            <Arrow fill={props.config.color} width="12" className={isVisible ? "comment-visible" : "comment-hidden"}/>
                        </Form.Label>
                        <Form.Control as="textarea" rows={3} className={isVisible ? "" : "d-none"} />
                    </Form.Group>
                    <Form.Group className="mb-3 required" controlId="fax">
                        <Form.Control type="text" autocomplete="off" placeholder={t('card.form.fax.label')} />
                        <Form.Control.Feedback type="valid">
                            {t('card.form.fax.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3 required" controlId="mailConfirm">
                        <Form.Control type="text" autocomplete="off" placeholder={t('card.form.mailConfirm.label')} />
                        <Form.Control.Feedback type="valid">
                            {t('card.form.mailConfirm.validation')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check>
                            <Form.Check.Input required />
                            <Form.Check.Label>
                                <Trans i18nKey="card.form.confirmation.label" link={props.config.linkToPrivacyPolicy}>
                                    Ich bin mit der Verarbeitung meiner personenbezogenen Daten gemäß der <a href={props.config.linkToPrivacyPolicy} target="_blank" rel="noreferrer">Datenschutzerklärung</a> einverstanden.
                                </Trans>
                            </Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                                {t('card.form.confirmation.validation')}
                            </Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                    <div className="submit col-12 d-grid">
                        <Button type="submit" disabled={disabled}><b className="submit-value">{t('card.form.send')}</b> {submitSelection}</Button>
                    </div>
                </div>
                {props.config.note && props.config.note !== '' &&
                    <div className="note mb-3 mt-3">
                        <div className="note-icon">
                            <Note fill={props.config.color} width="32"/>
                        </div>
                        <div className="note-text">
                            {props.config.note}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default FormComponent;