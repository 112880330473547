import './App.scss';
import React from 'react';
import Cookies from 'universal-cookie';
import sendData from "./components/api/Data";
import HeaderComponent from "./components/page/Header";
import FooterComponent from "./components/page/Footer";
import ContentComponent from "./components/page/Content";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {},
            isLoaded: false,
            companyName: ''
        }
    }

    componentDidMount() {
        this.setCompanyCookie();

        // get page data based on company
        sendData(null, 'getPageConfiguration').then(function (data) {
            this.setState({ config: data })
            this.setState({ isLoaded: true })
            // Todo: set theme meta color to brand color
            document.documentElement.style.setProperty('--brand-color',data.color);
            document.title = data.companyName + ' WebCalendar';
        }.bind(this));
    }

    /*
     * set company name as cookie for data relations
    */
    setCompanyCookie() {
        const cookies = new Cookies();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const company = urlParams.get('c')

        // redirect to avrio page, when company parameter not set
        if(company === null) {
            window.location.replace('https://www.avrio-marketing.de/');
        }

        cookies.set('company', company, { path: '/' });
        this.setState({ companyName: cookies.get('company') })
    }

    render() {
        const {isLoaded, config, companyName} = this.state;
        const appThemeColor = "App color-theme-" + config.colorTheme;

        if (isLoaded) {
            return (
                <div className={appThemeColor}>
                    <HeaderComponent config={config} company={companyName}/>
                    <ContentComponent config={config} />
                    <FooterComponent config={config}/>
                </div>
            )
        }
    }
}

export default App;
