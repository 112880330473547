import React from "react";
import sendData from "../../../../api/Data";
import FormCheck from "react-bootstrap/FormCheck";

/*
* Provide a list of available date times based on selected day
* */
class TimeSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            dateTimes: []
        }

        this.handleChange = this.handleChange.bind(this);
    }

    // check if selected date changed and get api data
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.dateString !== prevProps.dateString) {
            this.setState({isLoaded: false})
            let selectedDate = new Date(this.props.dateString);
            let selectedData =
                {
                    "category": this.props.selectedCategory,
                    "date": selectedDate.getFullYear() + '-' + (selectedDate.getMonth() + 1) + '-' + selectedDate.getDate()
                };
            sendData(selectedData, 'getDateTimes').then(function (data) {
                this.setState({dateTimes: data})
                this.setState({isLoaded: true})
            }.bind(this));
        }
    }

    // pass time data to parent component on change
    handleChange(event) {
        this.props.onTimeChange(event.target.value);
    }

    render() {
        const {isLoaded, dateTimes} = this.state;
        if (isLoaded) {
            let dateTimesList = dateTimes.map((dateTime) =>
                <div className="col-6">
                    <FormCheck>
                        <FormCheck.Input
                            className="btn-check"
                            type="radio"
                            name="dateTimeSelect"
                            id={dateTime}
                            value={dateTime}
                            onChange={this.handleChange} />
                        <FormCheck.Label
                            className="btn btn-secondary"
                            for={dateTime}
                        >{dateTime}</FormCheck.Label>
                    </FormCheck>
                </div>
            );

            return (
                <div className="timeSelect">
                    <div className="row gx-2 gy-2">
                        {dateTimesList}
                    </div>
                </div>
            );
        }
    }
};

export default TimeSelectComponent;