import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import text_de from "./translations/de/de.json";

i18next
    .use(initReactI18next)
    .init({
    fallbackLng: 'de',
    interpolation: {escapeValue: false},
    lng: 'de',
    resources: {
        de: {
            text: text_de
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <I18nextProvider i18n={i18next}>
          <App/>
      </I18nextProvider>
);

reportWebVitals();
